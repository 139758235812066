import { combineReducers } from 'redux';

import * as actions from '~/actions/reports';

const reports = () => {
  const isFetching = (state = false, action) => {
    switch (action.type) {
      case actions.FETCH_REPORT_REQUEST:
        return true;
      case actions.FETCH_REPORT_SUCCESS:
      case actions.FETCH_REPORT_FAILURE:
        return false;
      default:
        return state;
    }
  };

  const errorMessage = (state = null, action) => {
    switch (action.type) {
      case actions.FETCH_REPORT_FAILURE:
        return action.message;
      case actions.FETCH_REPORT_REQUEST:
      case actions.FETCH_REPORT_SUCCESS:
        return null;
      default:
        return state;
    }
  };

  const isFallback = (state = false, action) => {
    switch (action.type) {
      case actions.SET_IS_FALLBACK:
        return true;
      default:
        return state;
    }
  };

  return combineReducers({
    isFetching,
    errorMessage,
    isFallback,
  });
};

export default reports();

export const getIsFetching = state => state.isFetching;

