import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';
import Scroll from 'react-scroll';

import ContextBasedSearch from '~/containers/ContextBasedSearch';
import DropdownMenu from 'react-dd-menu';
import ReportHeaderRow from '~/components/ReportComponents/ReportHeaderRow';
import ReportHeaderItem from '~/components/ReportComponents/ReportHeaderItem';
import ReportActionsWrapper from '~/components/ReportComponents/ReportActionsWrapper';
import WorkspaceRow from '~/containers/WorkspaceRow';
import FeatureFlagHelper from '~/utils/FeatureFlagHelper';
import CSVReportDownloader from '~/containers/CSVReportDownloader';
import OrgPaidStatus from '~/utils/OrgPaidStatus';
import Tooltip from '~/components/Tooltip';

import SourceClearModal from '~/components/SourceClearModal';
import CreateWorkspaceModal from '~/containers/CreateWorkspaceModal';

import Pagination from '~/components/Pagination';
import LoaderWrapper from '~/components/LoaderWrapper';
import * as dropdownMenuActions from '~/actions/dropdownMenu';
import * as pageSizeActions from '~/actions/pageSize';
import * as reportActions from '~/actions/reports';
import * as reportFilterActions from '~/actions/reportFilters';
import * as sortByReportTypeActions from '~/actions/sortByReportType';
import * as orgTeamActions from '~/actions/orgTeam';
import * as teamActions from '~/actions/team';

import { FEATURE_SLUG_MAP, portfolioSearchContextOptions } from '~/constants/ModelConstants';
import { VCPageState } from '~/reducers/vcAppState/vcAppStateTypes/types';
import { RootState } from '~/reducers';
import PageSizeDropdown from './PageSizeDropdown';

import config from '~/config';

interface WorkspaceTableProps {
  dropdownMenuState: object;
  orgState: App.OrgState;
  reportFilterState: object;
  reports: object;
  reportsPDF: object;
  reportsByType: object;
  sortActions: object;
  sortByReportType: object;
  teamState: App.TeamState;
  vcPageState: VCPageState;
}

const REPORT_TYPE = 'WORKSPACES';

class WorkspaceTable extends React.Component<
  WorkspaceTableProps & ReturnType<typeof mapDispatchToProps>
> {
  componentDidMount() {
    this.refreshReportData();
  }

  componentWillUnmount() {
    this.closeDropdownMenu();
    this.showModal('');
  }

  delayedRefreshReportData = _.debounce(this.refreshReportData, 500);
  delayCloseDropdownMenu = _.debounce(this.closeDropdownMenu, 50);
  scroll = Scroll.animateScroll;

  refreshReportData() {
    this.props.pageSizeActions.updatePageSizeValue(config.REPORT_DEFAULT_PAGE_SIZE);
    this.props.reportActions.fetchOrgReport(REPORT_TYPE);
  }

  closeDropdownMenu() {
    this.props.dropdownMenuActions.closeDropdownMenu('portfoliopage-action');
  }

  handlePaginationClick(page) {
    const { reportActions } = this.props;

    reportActions.fetchOrgReport(REPORT_TYPE, page).then(() => {
      const current = document.getElementById('reportTop');
      const top = current.getBoundingClientRect().top;

      if (top < document.body.scrollTop) {
        this.scroll.scrollTo(top, { smooth: true, duration: 300 });
      }
    });
  }

  handlePageSizeChange = (pageSizeValue: number) => {
    const { pageSizeActions, reportActions } = this.props;
    pageSizeActions.updatePageSizeValue(pageSizeValue);
    reportActions.fetchOrgReport(REPORT_TYPE);
  };

  updateStringFilter(field: string, value) {
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, value);
    this.delayedRefreshReportData();
  }

  toggleBooleanFilter(field: string, isChecked: boolean) {
    this.props.reportFilterActions.updateFilterValue(REPORT_TYPE, field, isChecked);
    this.refreshReportData();
  }

  updateSortField(field: string) {
    this.props.sortActions.updateSortField(REPORT_TYPE, field);
    this.refreshReportData();
  }

  formatContextLabel(searchContextObject) {
    if (!searchContextObject || !searchContextObject.label) {
      return '';
    }

    let formattedLabel = searchContextObject.label.toLowerCase().replace(/cve/g, 'CVE'); //initial formatting

    if (searchContextObject.value !== 'WORKSPACE') {
      //Only capture first word of eg. 'library name' or 'project name' else if WORKSPACE capture entire label 'Workspace name'
      formattedLabel = formattedLabel.split(' ')[0];
    }

    return formattedLabel;
  }

  showModal(modalType) {
    this.props.teamActions.showModal(modalType);
  }

  handlePDFDownload = options => {
    this.props.reportActions.downloadPDF(options);
  };

  updateFallback = async () => {
    try {
      this.props.reportActions.setIsFallback();
      this.refreshReportData();
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      reportsByType,
      reports,
      reportsPDF,
      reportFilterState,
      sortByReportType,
      orgState,
      teamState,
      dropdownMenuState,
      vcPageState: { shouldShowVeracodePage },
    } = this.props;
    const { org } = orgState;
    const { showModal } = teamState;
    const { license, permissions } = org;
    const { createTeams, updateUsers } = permissions;
    const plan = org.plan || {};
    const { usage } = license;
    const { teams } = usage;
    const { id: planId, teamLimit } = plan;
    const { isFetching, errorMessage, isFallback } = reports;
    const { [REPORT_TYPE]: workspaceFilters } = reportFilterState;
    const { search = '', context = '' } = workspaceFilters;
    const { [REPORT_TYPE]: currentSort = {} } = sortByReportType;
    const { [REPORT_TYPE]: workspacesData = {} } = reportsByType;
    const { _embedded = {}, page = {} } = workspacesData;
    const { orgReportTeamList: workspaces = [] } = _embedded;
    const { openedDropdown = {} } = dropdownMenuState;

    const columnWidths = {
      name: 'col-1-6',
      lastScanDate: 'col-1-6',
      users: 'col-1-10',
      groups: 'col-1-8',
      actions: 'col-1-12',
      totalIssues: '',
      totalProjects: '',
      vulnIssues: '',
      libraryIssues: '',
      licenseIssues: '',
    };

    const showGroups =
      FeatureFlagHelper.isFeatureEnabledForOrg(FEATURE_SLUG_MAP.GROUPS, org) ||
      OrgPaidStatus.isOrgEnterpriseOrTrial(org);

    const { sso } = org;
    const samlDomains = org.samlDomains || [];
    const isSaml = !!samlDomains.length;
    const showDirectMembership = !isSaml && !sso;

    const hasPdfDownloadForWorkspace =
      workspaces.filter(workspace => {
        const { permissions = {}, repoCount = 0 } = workspace;
        const { reports = false } = permissions;
        return reports === true && repoCount !== 0;
      }).length > 0;

    // User is not a member of any workspace when list is not filtered and user has no workspace or only has My Sandbox workspace
    const isNotMemberOfAnyWorkspace =
      !search &&
      ((page.totalElements === 1 && workspaces[0].name === 'My Sandbox') ||
        page.totalElements === 0);

    const selectedContextObj =
      portfolioSearchContextOptions.find(option => option.value === context) || {};
    const contextLabelText = this.formatContextLabel(selectedContextObj);

    // For handling checks on workspace count and plan type limit

    const orgCannotCreateWorkspaces = planId === 1001;
    const orgHasReachedLimit = teamLimit && teamLimit <= teams;
    const showCreateWorkspace = createTeams && !orgCannotCreateWorkspaces;

    return (
      <div className={`flex flex-direction--column col-1-1`}>
        <div className="grid grid--narrower mt--">
          <div className="grid__item" id="reportTop">
            <ReportActionsWrapper>
              <div
                className="grid__item col-1-1 p-- mv-- flex flex--justify-content--end align-items--center height--40"
                data-automation-id="WorkspaceTable-ReportActions"
              >
                {showCreateWorkspace && (
                  <DropdownMenu
                    animate={true}
                    textAlign="left"
                    align="right"
                    isOpen={!!openedDropdown['portfoliopage-action']}
                    close={() => this.delayCloseDropdownMenu()}
                    toggle={
                      <button
                        className={`p- btn--success`}
                        onClick={() =>
                          this.props.dropdownMenuActions.openDropdownMenu('portfoliopage-action')
                        }
                      >
                        Actions
                      </button>
                    }
                  >
                    <li className="dropdown-menu--li">
                      <button
                        data-e2e="create-new-team-btn"
                        className={`btn--primary-clear bo--0 cursor--pointer flex justify-content--center align-items--center ph- pv-- ${
                          orgHasReachedLimit && 'disabled'
                        }`}
                        disabled={orgHasReachedLimit}
                        onClick={
                          orgHasReachedLimit ? undefined : () => this.showModal('CREATE_WORKSPACE')
                        }
                      >
                        {orgHasReachedLimit ? (
                          <Tooltip
                            id="workspace-limit"
                            content="You have reached the maximum number of workspaces for your organization. To create another workspace, delete an existing one or contact sales@sourceclear.com."
                            maxWidthClass="max-width--300"
                            place="right"
                          >
                            <span>Create Workspace</span>
                          </Tooltip>
                        ) : (
                          <span>Create Workspace</span>
                        )}
                      </button>
                    </li>
                  </DropdownMenu>
                )}
                <CSVReportDownloader
                  reportType={REPORT_TYPE}
                  page={page}
                  noElementText="No workspaces to download"
                />
              </div>
              <div className="grid_item col-1-1 p-- flex flex--justify-content--space-between bg-color--white-medium">
                <div
                  className="flex align-items--center"
                  data-automation-id="WorkspaceTable-Search"
                >
                  <ContextBasedSearch
                    contextOptions={portfolioSearchContextOptions}
                    search={search}
                    selectedContext={context}
                    name="portfolio-search-context"
                    onChange={(field, value) => this.updateStringFilter(field, value)}
                  />

                  {!errorMessage && (
                    <span className="pl-">
                      {page.totalElements} workspace
                      {page.totalElements === 1 ? '' : 's'}
                    </span>
                  )}
                  {search && <span>&nbsp;(searching by {contextLabelText})</span>}
                </div>
                <div className="flex">
                  <PageSizeDropdown
                    onChange={this.handlePageSizeChange}
                    value={config.REPORT_DEFAULT_PAGE_SIZE}
                  />
                  <Pagination
                    currentPage={page.number}
                    totalPages={page.totalPages || 1}
                    handlePageClick={page => this.handlePaginationClick(page)}
                  />
                </div>
              </div>
            </ReportActionsWrapper>
          </div>

          <div className="grid__item col-1-1 mt--">
            <ReportHeaderRow>
              <ReportHeaderItem
                label="Workspaces"
                field="name"
                widthClass={columnWidths.name}
                isSortable={true}
                onClick={field => this.updateSortField(field)}
                currentSort={currentSort}
                automationId="ReportHeaderItem-workspaceNames"
              />
              <ReportHeaderItem
                label="Total Projects"
                field="totalProjects"
                alignment="CENTER"
                widthClass={columnWidths.totalProjects}
                automationId="ReportHeaderItem-totalProjects"
              />
              {(isFallback === false) &&
                <>
                  <ReportHeaderItem
                    label="Total Issues"
                    field="totalIssues"
                    alignment="CENTER"
                    widthClass={columnWidths.totalIssues}
                    automationId="ReportHeaderItem-totalIssues"
                  />
                  <ReportHeaderItem
                    label="Vulnerability Issues"
                    field="vulnIssues"
                    alignment="CENTER"
                    widthClass={columnWidths.vulnIssues}
                    automationId="ReportHeaderItem-vulnIssues"
                  />
                  <ReportHeaderItem
                    label="Library Issues"
                    field="libraryIssues"
                    alignment="CENTER"
                    widthClass={columnWidths.libraryIssues}
                    automationId="ReportHeaderItem-libraryIssues"
                  />
                  <ReportHeaderItem
                    label="License Issues"
                    field="licenseIssues"
                    alignment="CENTER"
                    widthClass={columnWidths.licenseIssues}
                    automationId="ReportHeaderItem-licenseIssues"
                  />
                </>
              }
              <ReportHeaderItem
                label="Last Scan"
                field="lastScanDate"
                widthClass={columnWidths.lastScanDate}
                automationId="ReportHeaderItem-lastScanDate"
              />
              {!shouldShowVeracodePage && (
                <ReportHeaderItem label="Users" field="users" widthClass={columnWidths.users} />
              )}
              {showGroups && (
                <ReportHeaderItem
                  label="Teams"
                  field="groups"
                  widthClass={columnWidths.groups}
                  automationId="ReportHeaderItem-teams"
                />
              )}
              {hasPdfDownloadForWorkspace && (
                <ReportHeaderItem
                  label="Actions"
                  alignment="CENTER"
                  field="actions"
                  widthClass={columnWidths.actions}
                  automationId="ReportHeaderItem-actions"
                />
              )}
            </ReportHeaderRow>
          </div>
        </div>

        <LoaderWrapper className="col-1-1 pb+" isLoaderShowing={isFetching}>
          <div data-automation-id="WorkspaceTable-Rows">
            {workspaces.map((workspace, index) => {
              const { [workspace.id]: workspacePDF = {} } = reportsPDF;
              const { isFetching = false, error = '' } = workspacePDF;
              const rowId = `${index}-${workspace.name}-${workspace.lastScanDate}`;
              const { permissions = {}, repoCount = 0 } = workspace;
              const { reports = false } = permissions;
              const userCanShowPDFDownload = reports && repoCount > 0;
              return (
                <WorkspaceRow
                  key={rowId}
                  workspace={workspace}
                  rowId={rowId}
                  columnWidths={columnWidths}
                  hasAdminPerms={updateUsers}
                  showGroups={showGroups}
                  canShowPdfDownload={hasPdfDownloadForWorkspace}
                  userShowPdfDownload={userCanShowPDFDownload}
                  onPDFDownload={this.handlePDFDownload}
                  isPDFDownloading={isFetching}
                  hasErrorOnPDFDownload={!!error}
                  showDirectMembership={showDirectMembership}
                  shouldShowVeracodePage={shouldShowVeracodePage}
                  isFallback={isFallback}
                />
              );
            })}

            {search && !errorMessage && workspaces.length === 0 && (
              <h3 className="ml color--muted mt">No workspaces found.</h3>
            )}
            {!errorMessage && isNotMemberOfAnyWorkspace && (
              <h3 className="ml color--muted mt">
                You are not a member of any workspaces. <br />
                Contact your administrator to be added.
              </h3>
            )}
            {errorMessage && (
              <div className="bo--1 border-color--muted bg-color--white-light p">
                <div className="grid">
                  <div className="grid__item col-1-1 text--center">
                    <div className="font--h7">
                      We are currently experiencing an issue in fetching your work spaces. You may try using{' '}
                      {' '}
                      <a
                        href="#"
                        className="link--obvious"
                        onClick={this.updateFallback}
                      >
                        Lighter UI
                      </a>
                      {' '} or {' '}
                      <a
                        href="mailto:support@veracode.com?subject=Request For SourceClear Support"
                        className="link--obvious"
                      >
                        contact support
                      </a>{' '}
                      for assistance .{' '}
                    </div>
                  </div>
                </div>
              </div>
            )
            }
          </div>
        </LoaderWrapper>

        <SourceClearModal
          isOpen={showModal === 'CREATE_WORKSPACE'}
          onClose={() => this.showModal('')}
          width={500}
          title="Create Workspace"
        >
          <CreateWorkspaceModal
            showModal={modalType => this.showModal(modalType)}
            showGroups={showGroups}
          />
        </SourceClearModal>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    dropdownMenuState: state.dropdownMenuState,
    orgState: state.orgState,
    reportFilterState: state.reportFilterState,
    reports: state.reports,
    reportsPDF: state.reportsPDF,
    reportsByType: state.reportsByType,
    sortByReportType: state.sortByReportType,
    teamState: state.teamState,
    userStatus: state.userStatus,
    vcPageState: state.vcPageState,
    isFallback: state.reports.isFallback,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    dropdownMenuActions: bindActionCreators(dropdownMenuActions as any, dispatch),
    orgTeamActions: bindActionCreators(orgTeamActions as any, dispatch),
    pageSizeActions: bindActionCreators(pageSizeActions as any, dispatch),
    reportActions: bindActionCreators(reportActions as any, dispatch),
    reportFilterActions: bindActionCreators(reportFilterActions as any, dispatch),
    sortActions: bindActionCreators(sortByReportTypeActions as any, dispatch),
    teamActions: bindActionCreators(teamActions as any, dispatch),
    setIsFallback: () => dispatch(reportActions.setIsFallback()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceTable);
